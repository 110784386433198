<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="10">
                <v-card outlined>
                    <v-card-title class="blue white--text py-2">
                        Configuration

                        <v-spacer />

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark @click="saveConfig()" v-on="on">
                                    <v-icon> fas fa-download </v-icon>
                                </v-btn>
                            </template>
                            Télécharger
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon dark @click="uploadConfig()" v-on="on">
                                    <v-icon> fas fa-upload </v-icon>
                                </v-btn>
                            </template>
                            Uploader
                        </v-tooltip>
                    </v-card-title>

                    <v-card-text class="pt-4">
                        <v-jsoneditor v-model="config" :height="'400px'" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Parameters',

    methods: {
        saveConfig() {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.config, null, 4)));
            element.setAttribute('download', 'config.json');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },

        uploadConfig() {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.style.display = 'none';
            document.body.appendChild(input);

            input.addEventListener('change', () => {
                const file = input.files[0];
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    this.config = JSON.parse(event.target.result);
                });
                reader.readAsText(file);
            });

            input.click();
        }
    },

    computed: {
        config: {
            get() {
                return this.getConfig('*');
            },

            set(config) {
                this.$store.commit('application/setConfig', config);
            }
        }
    }
};
</script>
